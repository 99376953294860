import { IError } from "flowy-3-core";
import { Modal, Typography, Button } from "antd";
import { FC, useEffect, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";

type FormErrorsProps = {
  errors: IError[];
};

const { Text } = Typography;

const FormErrors: FC<FormErrorsProps> = ({ errors }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (errors.length > 0) {
      setVisible(true);
    }
  }, [errors]);

  return (
    <div>
      {errors.length > 0 && (
        <Modal
          open={visible}
          title={
            <div>
              <CloseCircleFilled style={{ color: "red" }} /> Error
            </div>
          }
          footer={[
            <Button
              style={{ backgroundColor: "#AB0A3D", color: "white" }}
              onClick={() => setVisible(false)}
            >
              Aceptar
            </Button>,
          ]}
          style={{ top: 20 }}
        >
          {errors.length > 0 &&
            errors.map((error: IError) => (
              <span key={error.fid}>
                <Text>{error.message}</Text>
                <br />
              </span>
            ))}
        </Modal>
      )}
    </div>
  );
};

export default FormErrors;
