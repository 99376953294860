import { FC } from "react";
import { RootGizmo, useRootGizmo } from "flowy-3-core";
import * as S from "./Form.styles";
import renderGizmos from "../../render-gizmos";
import FormInfo from "./FormInfo";
import Status from "./Status";
import Errors from "../../utils/Errors/Errors";
import FormErrors from "./FormErrors";

type FormProps = {
  gizmo: RootGizmo;
};

const Form: FC<FormProps> = ({ gizmo }) => {
  const { features, screenGizmos, errors } = useRootGizmo({ gizmo });

  return (
    <S.Form id={`form-${gizmo.getConfig().fid}`}>
      {gizmo.getParams()["m"] === "s" && <FormInfo gizmo={gizmo} />}
      <Status rootGizmo={gizmo} />
      {features.displayLabel && <h1>{features.label}</h1>}
      {screenGizmos.length > 0 && renderGizmos(screenGizmos)}
      {/* <Errors errors={errors} /> */}
      <FormErrors errors={errors} />
    </S.Form>
  );
};

export default Form;
