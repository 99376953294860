import { FC, useState, useEffect } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import dayjs from "dayjs";
import * as S from "./DateField.styles";
import { Errors, GizmoDisplay, GizmoLabel, GizmoWrapper } from "../../utils";
import { DatePickerProps, Form } from "antd";
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

type DateFieldProps = {
  gizmo: Gizmo;
};

const { Item } = Form;

const DateField: FC<DateFieldProps> = ({ gizmo }) => {
  const { features, binder, errors, config } = useGizmo({ gizmo });
  const [value, setValue] = useState<string>("");
  const [format, setFormat] = useState<string>("");
  const [useTwelveHours, setUseTwelveHours] = useState<boolean>(false);

  const handleChange: DatePickerProps["onChange"] = async (_, valueString) => {
    await binder?.setValue(valueString);
  };

  useEffect(() => {
    if (binder) {
      const initValue = binder.getValue();
      setFormat(binder.getFormat());
      if (initValue) {
        setValue(initValue);
      }
      if (config.ops?.dateField?.useTwelveHours === true) {
        setUseTwelveHours(true);
      }

      binder.value.subscribe((v: string) => {
        setValue(v);
      });
    }
  }, [binder]);

  return (
    <GizmoWrapper features={features} errors={errors}>
      {!features.editable ? (
        <span>{value}</span>
      ) : config.ops?.dateField?.mode === "time" ? (
        <S.TimeField
          id={`date_field-${config.fid}`}
          onChange={handleChange}
          use12Hours={useTwelveHours}
          format={format}
          value={value?.length ? dayjs(value, format) : undefined}
        />
      ) : (
        <S.DateField
          id={`date_field-${config.fid}`}
          onChange={handleChange}
          placeholder={format}
          value={value?.length ? dayjs(value, format) : undefined}
          format={format}
          locale={locale}
        />
      )}
    </GizmoWrapper>
  );
};

export default DateField;
