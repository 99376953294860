import { FlowyProject } from "../components/gizmos";
import usePlatformCB from "./usePlatformCB";
import * as S from "./BaseProject.styles";
import { defaultCoreProject } from "./gizmoConfigurations";
import clone from "clone";
import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import { useFlowyGlobalContext } from "flowy-3-core";

/**
 *  We created this in order to not touch the DefaultProject component.
 */
const BaseProject: React.FC = () => {
  // Uncomment this to use the default project
  // const [project, setProject] = useState<any>(defaultCoreProject);
  const [project, setProject] = useState<any>(undefined);
  const { isAuthenticated } = useAuth();
  const { platformOperationCB, params } = usePlatformCB();
  const flowyGlobal = useFlowyGlobalContext();

  useEffect(() => {
    if (isAuthenticated !== undefined && flowyGlobal) {
      const projects = flowyGlobal.getProjects({
        authenticated: isAuthenticated,
      });
      if (projects && projects.length > 0) {
        setProject(projects[0]);
      }
      flowyGlobal.projects.subscribe((projects) => {
        // setProject(clone(projects[0]));
        setProject(undefined);
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (project === undefined && flowyGlobal && isAuthenticated !== undefined) {
      const projects = flowyGlobal.getProjects({
        authenticated: isAuthenticated,
      });
      if (projects && projects.length > 0) {
        setProject(projects[0]);
      }
    }
  }, [project]);

  const handleButtonClick = async () => {
    await flowyGlobal?.sync();
  };

  return (
    <S.BaseProject>
      {project && (
        <FlowyProject
          config={project}
          platformCallbacks={{
            platformOperationCB,
          }}
          params={params}
        />
      )}
    </S.BaseProject>
  );
};

export default BaseProject;
